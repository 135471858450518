import React from "react"
import IntegrationTemplate from "../../templates/integration"
import * as Ui from "components/ui"
import * as Sections from 'components/sections'
import * as Layout from 'components/layout'
import IntegrationStorageContent from 'components/content/integration-storage'
import { meta } from '../../configs/storage-providers';
const ContentPage = () => (
  <IntegrationTemplate
    key="simple"
    name="SimpleStorage"
    shortDescription="Our managed storage solution for your backups.SimpleStorage is our backups storage solution included in all our paid plan."
    website={"https://simplebackups.com/"}
    links={[{text: 'Documentation', url: '/docs'}]}
    category="Storage"
    logo="simple-ico.png"
    metaTitle={meta.title.replace('##provider##', 'SimpleStorage')}
    metaDescription={meta.description.replace('##provider##', 'SimpleStorage')}
  >
    <IntegrationStorageContent
      keyRef="simplestorage"
      service="SimpleStorage"
      provider="SimpleStorage"
      learnTags={['simplestorage']}
    />
  </IntegrationTemplate>
)

export default ContentPage
